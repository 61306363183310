import { ECreditApplicationStatus, EDecision, IconType } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { CircularProgress, Typography } from '@mui/material'
import { anyDocsAwaitingApproval } from '@src/data/creditapp-selectors'
import { useTranslation } from 'react-i18next'
import PrequalApproved from './PrequalApproved'
import PrequalDeclined from './PrequalDeclined'
import StepStatusSection from '../../../App/components/StepStatusSection'
import PrequalNotStarted from './PrequalNotStarted'
import PrequalDocumentRequired from './PrequalDocumentRequired'
import { GetDefaultIconAndMessageFromDecision } from '../../viewCreditApp-selectors'
import ApplicationError from '../applicationError'
import { canPollCreditApp, isServerAnalysingCredit } from '../../credit-hooks'

type Props = {
  creditApp: CreditApplication
}

const PrequalTile = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const isAnalysing = isServerAnalysingCredit(creditApp) && canPollCreditApp(creditApp)
  const docsAwaitingApproval = anyDocsAwaitingApproval(creditApp.requiredDocuments)

  const icon =
    creditApp.prequalificationDecision?.decision === EDecision.Pending &&
    creditApp.status !== ECreditApplicationStatus.Cancelled &&
    !docsAwaitingApproval
      ? { iconMessage: ECreditApplicationStatus.DocumentsRequired, iconType: IconType.warning }
      : GetDefaultIconAndMessageFromDecision(
          creditApp.prequalificationDecision?.decision,
          creditApp,
          isAnalysing,
          docsAwaitingApproval,
        )
  return (
    <StepStatusSection
      title={t('viewCreditApplication.prequalificationSectionTitle')}
      iconMessage={icon.iconMessage ? t(`creditApplicationStatus.${icon.iconMessage}`) : null}
      iconType={icon.iconType}
    >
      {creditApp.status === ECreditApplicationStatus.Cancelled &&
        creditApp.prequalificationDecision?.decision !== EDecision.Accept && (
          <Typography variant="body1">{t('viewCreditApplication.creditAppCancelled')}</Typography>
        )}
      {creditApp.status === ECreditApplicationStatus.Draft && <PrequalNotStarted />}
      {creditApp.status === ECreditApplicationStatus.Active &&
        isAnalysing &&
        creditApp.prequalificationDecision === null && (
          <CircularProgress style={{ margin: 'auto', display: 'block' }} />
        )}
      {creditApp.prequalificationDecision?.decision === EDecision.Accept && <PrequalApproved creditApp={creditApp} />}
      {icon.iconMessage === ECreditApplicationStatus.Error && <ApplicationError />}
      {(icon.iconMessage === ECreditApplicationStatus.Declined || !creditApp.consentSoftHit) && (
        <PrequalDeclined creditApp={creditApp} />
      )}
      {icon.iconMessage === ECreditApplicationStatus.DocumentsRequired && (
        <PrequalDocumentRequired creditApp={creditApp} />
      )}
      {icon.iconMessage === ECreditApplicationStatus.AwaitingApproval && (
        <Typography>{t('viewCreditApplication.clientManualRevision')}</Typography>
      )}
    </StepStatusSection>
  )
}

export default PrequalTile
