import {
  MutationFunction,
  QueryFunctionContext,
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { EFinancingProgram } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { Contract } from '@src/types/Contract'
import { ComputeMonthlyTermsResponseDto } from '@src/types/ComputeMonthlyTermsResponseDto'
import { ComputeMonthTermFrequencyDto } from '@src/types/ComputeMonthTermFrequencyDto'
import { isComputePossibleTermsDtoValid } from '@src/data/worksheet-selectors'
import { getApiClient } from '../api-client'

const SCOPE = 'worksheet'
const SCOPEHOLIDAYS = 'holidays'
const DETAIL = 'detail'
const CREDIT_APP_SCOPE = 'credit-applications'
const MONTHLYTERMS = 'monthlyTerms'

const keysFactory = {
  amountForFrequency: (termsRequestParams: ComputeMonthTermFrequencyDto | null) =>
    [{ scope: MONTHLYTERMS, ...termsRequestParams }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
  holidays: (year: number) => [{ scope: SCOPEHOLIDAYS, year }] as const,
  creditAppDetail: (id: string, financingProgramId: string) =>
    [{ scope: CREDIT_APP_SCOPE, entity: DETAIL, id, financingProgramId }] as const,
}

const GetHolidaysForYear = async ({
  queryKey: [{ year }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['holidays']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<Date[]>(`/Holidays/${encodeURIComponent(year)}`)
  return response.data
}

export function useGetHolidaysForYear(year: number): [Date[], boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.holidays(year),
    queryFn: GetHolidaysForYear,
  })

  return [data!, isFetching]
}

const sendSignatureLink: MutationFunction<
  Contract,
  { financingProgramId: EFinancingProgram; creditApplicationId: string }
> = async ({ financingProgramId, creditApplicationId }) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<Contract>(
    `/Contract/${financingProgramId}/${creditApplicationId}/SendSignatureLink`,
  )
  return response.data
}

export function useSendSignatureLink(
  financingProgramId: EFinancingProgram,
  creditApplicationId: string,
): [UseMutateAsyncFunction<Contract, Error, string, unknown>, boolean, boolean, boolean] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, isSuccess } = useMutation<Contract, Error, string>({
    mutationFn: () => sendSignatureLink({ financingProgramId, creditApplicationId }),
    onSuccess: (data) => {
      queryClient.setQueryData(
        keysFactory.creditAppDetail(creditApplicationId, financingProgramId),
        (oldData: CreditApplication) => {
          return {
            ...oldData,
            contract: data,
          }
        },
      )
    },
  })

  return [mutateAsync, isPending, isError, isSuccess]
}

const GetPossibleTerms = async ({
  queryKey: [termsRequestParams],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['amountForFrequency']>>) => {
  const response = await getApiClient().post(
    `Worksheet/${EFinancingProgram.Personal}/CalculateMonthlyTerms`,
    termsRequestParams,
  )

  return response.data as ComputeMonthlyTermsResponseDto
}

export function useGetPossibleTerms(
  computeParams: ComputeMonthTermFrequencyDto,
): [ComputeMonthlyTermsResponseDto, boolean] {
  const { isPending, data } = useQuery({
    queryKey: [...keysFactory.amountForFrequency(computeParams)],
    queryFn: GetPossibleTerms,
    enabled: isComputePossibleTermsDtoValid(computeParams),
  })

  return [data!, isPending]
}
