import { Grid } from '@mui/material'
import { DatePicker } from '@src/components/DatePicker'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import { formatDate } from '@src/services/Formatter'
import { EPaymentPlan } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import { ProductsWorksheet } from '@src/types/WorksheetSchema'
import { Control, FieldErrors, UseFormRegister, UseFormWatch, UseFormSetValue, UseFormGetValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useProductsFirstPaymentDateOptions } from '@src/containers/ViewCreditApplicationPage/worksheet-hooks'
import { programConfigByFinancingProgramId } from '@src/data/creditapp-selectors'

type Props = {
  creditApplication: CreditApplication

  control: Control<ProductsWorksheet>

  register: UseFormRegister<ProductsWorksheet>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>
  setValue: UseFormSetValue<ProductsWorksheet>
  getValues: UseFormGetValues<ProductsWorksheet>
  errors: FieldErrors<ProductsWorksheet>
  disabledAmountInput?: boolean
  maxTermDuration?: number
  loanTermChoicesList: Array<SelectValueListItem>
}

const ProductsWorksheetDetails = ({
  creditApplication,
  register,
  errors,
  control,
  watch,
  setValue,
  getValues,
  disabledAmountInput,
  loanTermChoicesList,
}: Props) => {
  const { t } = useTranslation()
  const deliveryOn = watch('deliveryOn') as string

  const [firstPaymentDateOptions, setFirstPaymentOptions] = useState<Date[]>([])
  const computeProductsFirstPaymentDateOptions = useProductsFirstPaymentDateOptions()

  // recompute first payment options and date
  useEffect(() => {
    if (deliveryOn) {
      const options = computeProductsFirstPaymentDateOptions(deliveryOn)
      setFirstPaymentOptions(options)

      const currentFirstPaymentDate = getValues('firstPaymentOn')
      const currentFirstPaymentDateAsString = formatDate(currentFirstPaymentDate)
      const availableDatesAsString = options.map((item) => formatDate(item))

      if (availableDatesAsString.length > 0 && !availableDatesAsString.includes(currentFirstPaymentDateAsString)) {
        setValue('firstPaymentOn', options[0])
      }
    }
  }, [deliveryOn, computeProductsFirstPaymentDateOptions, getValues, setValue])

  const programConfig = programConfigByFinancingProgramId(creditApplication.financingProgramId)
  const availableFrequencyPayments = programConfig.frequencyPayments

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={4}>
        <InputTextField
          label={t('worksheet.requestedAmount')}
          fullWidth
          disabled={disabledAmountInput}
          {...register('amountRequested')}
          error={errors.amountRequested}
          defaultValue={creditApplication?.worksheet?.amountRequested}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <DatePicker
          name="deliveryOn"
          control={control}
          error={errors?.deliveryOn}
          label={t('worksheet.activationDate')}
          disablePast
          minDate={new Date()}
          maxDate={creditApplication?.expiresOn}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          valueList={loanTermChoicesList}
          label={t('worksheet.loanTerm') as string}
          {...register('term')}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          valueList={availableFrequencyPayments.map((item) => {
            return { label: `common.${item}`, value: item }
          })}
          label={t('worksheet.paymentFrequency') as string}
          {...register('paymentFrequency')}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <DatePicker
          name="firstPaymentOn"
          control={control}
          error={errors?.firstPaymentOn}
          label={t('worksheet.firstPaymentOn')}
          disablePast
          minDate={firstPaymentDateOptions[0]}
          maxDate={firstPaymentDateOptions[firstPaymentDateOptions.length - 1]}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          valueList={[
            {
              label: t(`ePaymentPlan.${EPaymentPlan.regularDailyInterests}`),
              value: EPaymentPlan.regularDailyInterests,
            },
          ]}
          label={t('worksheet.paymentPlan') as string}
          {...register('paymentPlanId')}
          error={errors?.paymentPlanId}
          disabled
        />
      </Grid>
    </Grid>
  )
}

export default ProductsWorksheetDetails
