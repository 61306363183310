import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import { ArrowBack as ArrowBackIcon, Refresh as RefreshIcon } from '@mui/icons-material'
import { Trans, useTranslation } from 'react-i18next'
import { formatDateTime } from '../../../services/Formatter'

type Props = {
  updatedByUserFullname: string | null
  updatedOn: Date | null
  open: boolean
  onClose: () => void
}

const ConflictDialog = ({ updatedByUserFullname, updatedOn, open, onClose }: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{t('conflict.modificationConflict')}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Typography gutterBottom>
            <Trans
              i18nKey="conflict.cantSaveModifiedBy"
              values={{
                updatedByUserFullname,
                updatedOn: formatDateTime(updatedOn),
              }}
            />
          </Typography>
          <Typography>{t('conflict.closeOrReload')}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose} startIcon={<ArrowBackIcon />}>
          {t('conflict.backToWork')}
        </Button>
        <Button
          variant="contained"
          color="warning"
          onClick={() => window.location.reload()}
          startIcon={<RefreshIcon />}
        >
          {t('conflict.reloadPage')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConflictDialog
