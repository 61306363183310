import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { CircularProgress, Typography } from '@mui/material'
import { EContractStatus, ECreditApplicationStatus, EDocType } from '@src/types/Constants'
import { useTranslation } from 'react-i18next'
import StepStatusSection from '../../../App/components/StepStatusSection'
import { GetFundingIconAndMessage } from '../../viewCreditApp-selectors'

import { canPollCreditApp, isServerAnalysingCredit } from '../../credit-hooks'
import FundingCreatingLoan from './FundingCreatingLoan'
import FundingLoanCreated from './FundingLoanCreated'
import FundingLoanCompleted from './FundingLoanCompleted'

type Props = {
  creditApp: CreditApplication
}
const FundingTile = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const isAnalysing = isServerAnalysingCredit(creditApp) && canPollCreditApp(creditApp)
  const icon = GetFundingIconAndMessage(creditApp)
  const today = new Date(`T00:00:00.000Z`)

  if (
    creditApp.electronicSignatures?.length > 0 ||
    creditApp.requiredDocuments.some((doc) => doc.typeId === EDocType.signedCvt) ||
    creditApp.contract?.status === EContractStatus.completed
  ) {
    return (
      <StepStatusSection
        title={t('common.funding')}
        iconMessage={icon.iconMessage ? t(`creditApplicationStatus.${icon.iconMessage}`) : null}
        iconType={icon.iconType}
      >
        {isAnalysing ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {icon.iconMessage === ECreditApplicationStatus.DocumentsRequired && (
              <Typography variant="body1">{t('viewCreditApplication.requiredDocuments')}</Typography>
            )}
            {icon.iconMessage === ECreditApplicationStatus.ManualRevision && (
              <Typography variant="body1">{t('viewCreditApplication.documentsAwaitingApproval')}</Typography>
            )}
            {icon.iconMessage === ECreditApplicationStatus.Processing && !creditApp.loanCreated && (
              <FundingCreatingLoan id={creditApp.id} financingProgramId={creditApp.financingProgramId} />
            )}
            {icon.iconMessage === ECreditApplicationStatus.Processing &&
              creditApp.loanCreated &&
              new Date(creditApp.deliveryOn) <= today && (
                <FundingLoanCreated
                  activationDate={creditApp.deliveryOn}
                  id={creditApp.id}
                  financingProgramId={creditApp.financingProgramId}
                />
              )}
            {icon.iconMessage === ECreditApplicationStatus.Completed && (
              <FundingLoanCompleted id={creditApp.id} financingProgramId={creditApp.financingProgramId} />
            )}
          </>
        )}
      </StepStatusSection>
    )
  }
  return (
    <StepStatusSection title={t('common.funding')}>
      <Typography variant="body1">{t('viewCreditApplication.fundingNotStarted')}</Typography>
    </StepStatusSection>
  )
}

export default FundingTile
