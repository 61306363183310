import { EFrequencyType, EProvince, ETerms, ETermsList } from '@src/types/Constants'
import i18n from '@src/services/i18n'
import * as yup from './common/yup-extended'
import yupExtInt from './common/SchemaTypes'

export const CalculatorSchema = yup.default.object().shape({
  stateIso: yup.default.mixed<EProvince>().oneOf(Object.values(EProvince)),
  frequency: yup.default.mixed<EFrequencyType>().oneOf(Object.values(EFrequencyType), 'common.errors.invalidFrequency'),
  terms: yup.default.mixed<ETerms>().oneOf(ETermsList, 'common.errors.invalidTerm'),

  amountRequested: yupExtInt.integer
    .min(750, () => i18n.t('common.errors.minimumAmount', { min: 750 }))
    .max(25000, () => i18n.t('common.errors.maximumAmount', { max: 25000 }))
    .required(() => i18n.t('common.errors.required')),
})

export type CalculatorSchemaType = yup.default.InferType<typeof CalculatorSchema>
