import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Box, Grid, Paper, Stack, Typography, InputAdornment, CardContent, CardHeader } from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { Constants } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { Merchant } from '@src/types/Merchant'
import { ProductWorksheetSchema, ProductsWorksheet } from '@src/types/WorksheetSchema'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import InputTextField from '@src/components/InputTextField'
import { CardBorderTop } from '@src/components/common/Card'
import useProductsProviders from '@src/data/api/products-api/products-api'
import PageSpinner from '@src/components/PageSpinner'
import { reportErrorToConsole } from '@src/services/error-logger'
import { usePostProductsWorksheet, usePutProductsWorksheet } from '@src/data/api/worksheet-api/product-worksheet-api'
import { ProductsComputeRequestDto } from '@src/types/ProductsComputeRequestDto'
import useComputeProductsLoan from '@src/data/api/calculator-api/calculator-api'
import { useComputedPossibleTerms } from '../../ViewCreditApplicationPage/worksheet-hooks'
import ProductsInsurances from './Components/ProdutsInsurances'
import ProductsLoanFundingSummary from './Components/ProductsFundindSummary'
import ProductsWorksheetDetails from './Components/ProductsWorksheetDetails'

type Props = {
  merchant: Merchant
  creditApplication: CreditApplication
  merchantPayments: { label: string; value: string; id: string }[]
}

const ProductsWorksheetPage = ({ merchant, creditApplication }: Props) => {
  const defaultValues = creditApplication.worksheet ?? ProductWorksheetSchema.getDefault()

  const {
    control,
    register,
    setValue,
    watch,
    getValues,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<ProductsWorksheet>({
    mode: 'onBlur',
    defaultValues: defaultValues as unknown as ProductsWorksheet,
    resolver: yupResolver(ProductWorksheetSchema),
  })

  const vehicleFinancingTerm = watch('vehicleFinancingTerm')
  useEffect(() => {
    // eslint-disable-next-line no-void
    void trigger(['creditInsurance', 'extendedWarranty', 'replacementOrGapInsurance'])
  }, [trigger, vehicleFinancingTerm])

  const [postWorksheet, isPosting, isPostError, postError] = usePostProductsWorksheet()

  const [putWorksheet, isPuting, isPutError, updateError] = usePutProductsWorksheet()

  const [fundingData, setFundingData] = useState<ProductsComputeRequestDto | undefined>(undefined)

  const [computedFunding, isComputing] = useComputeProductsLoan(fundingData)

  const [productsProviders, isLoadingProductProviders] = useProductsProviders()

  const navigate = useNavigate()
  const { t } = useTranslation()

  const navigateToView = () => {
    navigate(`/credits/${creditApplication.financingProgramId}/${creditApplication.id}`, { replace: true })
  }

  const onSubmit = async (formData: ProductsWorksheet) => {
    formData.creditApplicationId = creditApplication.id
    if (creditApplication.worksheet == null) {
      await postWorksheet({ worksheet: formData }).then(() => {
        navigateToView()
      })
    } else {
      await putWorksheet({
        worksheet: formData,
      }).then(() => {
        navigateToView()
      })
    }
  }

  const [creditInsuranceTerm, replacementOrGapInsuranceTerm, extendedWarrantyTerm] = watch([
    'creditInsurance.term',
    'replacementOrGapInsurance.term',
    'extendedWarranty.term',
  ])

  const maxTermDuration = useMemo(() => {
    let value = creditApplication.finalCreditDecision.maxTermDuration ?? 0
    const terms = [creditInsuranceTerm, replacementOrGapInsuranceTerm, extendedWarrantyTerm]

    terms.forEach((e) => {
      if (e && (e < value || value === 0)) value = e
    })
    return value
  }, [
    creditApplication.finalCreditDecision.maxTermDuration,
    creditInsuranceTerm,
    extendedWarrantyTerm,
    replacementOrGapInsuranceTerm,
  ])

  const [amountRequested, paymentFrequency, term] = watch(['amountRequested', 'paymentFrequency', 'term'])

  useEffect(() => {
    if ([amountRequested, paymentFrequency, term].every((x) => x !== undefined && x !== null)) {
      const calculationData = {
        province: creditApplication.applicant.currentAddress.stateIso,
        amountRequested: amountRequested ?? 0,
        frequency: paymentFrequency,
        term,
      } as unknown as ProductsComputeRequestDto

      setFundingData(calculationData)
    }
  }, [creditApplication.applicant.currentAddress.stateIso, amountRequested, paymentFrequency, term])

  const possibleTerms = useComputedPossibleTerms(maxTermDuration)

  const loanTermChoicesList =
    possibleTerms.map((item) => ({
      label: `${item} ${t('common.month')}`,
      value: item,
    })) || []

  if (isLoadingProductProviders) return <PageSpinner withBackdrop isLoading />

  return (
    <div>
      {(postError || updateError) && (
        <Paper>
          <Alert severity="error">
            <Typography>{postError?.response?.data?.message || updateError?.response?.data?.message}</Typography>
          </Alert>
        </Paper>
      )}
      <form onSubmit={handleSubmit(onSubmit, reportErrorToConsole)}>
        <Stack direction={{ md: 'column', xs: 'column-reverse' }} spacing={5} justifyContent="space-between">
          <Stack
            direction={{ sm: 'row', xs: 'column' }}
            spacing={2}
            justifyContent="space-between"
            alignItems={{ sm: 'self-end', xs: 'self-start' }}
          >
            <Stack direction="column" justifyContent="space-between" spacing={2}>
              <Typography variant="h4">{t('worksheet.worksheet')}</Typography>
            </Stack>
            <Stack direction="row" spacing={2} display="flex">
              <AsyncActionButton fullWidth={false} variant="contained" onClick={() => navigate(-1)}>
                {t('common.previous')}
              </AsyncActionButton>

              <AsyncActionButton
                fullWidth={false}
                variant="contained"
                disabled={isComputing || !computedFunding}
                isPending={isPosting || isPuting}
                isError={isPostError || isPutError}
                onClick={handleSubmit(onSubmit)}
              >
                {t('worksheet.createContract')}
              </AsyncActionButton>
            </Stack>
          </Stack>

          <Stack direction={{ md: 'row', xs: 'column-reverse' }} spacing={3} justifyContent="space-between">
            <Box sx={{ flex: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputTextField
                      label={t('productsWorksheet.vehicleFinancingTerm')}
                      {...register('vehicleFinancingTerm')}
                      error={errors.vehicleFinancingTerm}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{t('common.month')}</InputAdornment>,
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1" component={Alert} severity="info">
                    {t('productsWorksheet.optional', { amount: Constants.ProductMinimumLoanAmount })}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <ProductsInsurances
                    errors={errors}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    control={control}
                    getValues={getValues}
                    trigger={trigger}
                    stateIso={creditApplication.applicant.currentAddress.stateIso!}
                    insurancesProvider={productsProviders.filter((x) => merchant.insuranceProviderIds.includes(x.id))}
                    warrantyProvider={productsProviders.filter((x) => merchant.warrantyProviderIds.includes(x.id))}
                  />
                </Grid>

                <Grid item xs={12}>
                  <CardBorderTop>
                    <CardHeader title={t('common.financing')} />
                    <CardContent>
                      <ProductsWorksheetDetails
                        disabledAmountInput
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        watch={watch}
                        register={register}
                        errors={errors}
                        creditApplication={creditApplication}
                        maxTermDuration={maxTermDuration}
                        loanTermChoicesList={loanTermChoicesList}
                      />
                    </CardContent>
                  </CardBorderTop>
                </Grid>
              </Grid>
            </Box>

            <ProductsLoanFundingSummary computedFunding={computedFunding} isFetching={isComputing} />
          </Stack>
        </Stack>
      </form>
    </div>
  )
}

export default ProductsWorksheetPage
