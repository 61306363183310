import { reportErrorToConsole } from '@src/services/error-logger'
import { FormatCurrency, formatDate } from '@src/services/Formatter'
import { ComputeMonthTermFrequencyDto } from '@src/types/ComputeMonthTermFrequencyDto'
import { ComputeFundingDto } from '@src/types/ComputePersonalLoanFundingDto'
import { ComputeFundingResultDto } from '@src/types/ComputePersonalLoanFundingResultDto'
import { EFrequencyType, EPaymentPlan, EProvince } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { Merchant } from '@src/types/Merchant'
import { MerchantPaymentPlan } from '@src/types/MerchantPaymentPlan'
import {
  EditPersonalLoanWorksheetDto,
  EditPersonalLoanWorksheetDtoSchema,
  worksheetDetailsSchema,
  computePossibleTermsDtoSchema,
} from '@src/types/WorksheetSchema'
import { isWeekend, isLastDayOfMonth, addDays, addMonths, isValid, parse, setDate } from 'date-fns'
import { subtract } from 'lodash-es'

export function createNewPersonalLoanWorksheetDto(id: string, data: EditPersonalLoanWorksheetDto) {
  const newWorksheet = {
    ...(EditPersonalLoanWorksheetDtoSchema.getDefault() as unknown as EditPersonalLoanWorksheetDto),
    includeInsurance: data.includeInsurance,
  } as EditPersonalLoanWorksheetDto
  newWorksheet.amountRequested = data.amountRequested
  newWorksheet.deliveryOn = data.deliveryOn
  newWorksheet.firstPaymentOn = data.firstPaymentOn
  newWorksheet.paymentFrequency = data.paymentFrequency
  newWorksheet.term = data.term
  newWorksheet.merchantPayments = data.merchantPayments
  newWorksheet.paymentPlanId = data.paymentPlanId
  newWorksheet.creditApplicationId = id

  return newWorksheet
}

export function updatePersonalLoanWorksheet(data: EditPersonalLoanWorksheetDto, creditApp: CreditApplication) {
  if (creditApp.worksheet) {
    creditApp.worksheet.amountRequested = data.amountRequested
    creditApp.worksheet.deliveryOn = data.deliveryOn
    creditApp.worksheet.firstPaymentOn = formatDate(data.firstPaymentOn)
    creditApp.worksheet.paymentFrequency = data.paymentFrequency
    creditApp.worksheet.term = data.term
    creditApp.worksheet.merchantPayments = data.merchantPayments
    creditApp.worksheet.paymentPlanId = data.paymentPlanId
    creditApp.worksheet.includeInsurance = data.includeInsurance
  }
  return creditApp.worksheet
}

const possibleFirstPaymentDates = [1, 15, 23]

export const provinceSupportsInsurance = (stateIso: EProvince | null | undefined): boolean => {
  return stateIso !== EProvince.quebec && stateIso !== EProvince.saskatchewan
}

const isNotEndOfMonthAndWeekday = (date: Date) => isWeekend(date) && !isLastDayOfMonth(date)

const isHoliday = (date: Date, listHolidays: Date[]) => {
  const formattedDate = formatDate(date)
  return listHolidays?.toString().includes(formattedDate)
}

export const cantSelectDate = (date: Date, listHolidays: Date[]) => {
  return isHoliday(date, listHolidays) || isNotEndOfMonthAndWeekday(date)
}

const nthBusinessDayAfterDate = (startDate: Date, nthBusinessDay: number, listHolidays: Date[]) => {
  let n: number = nthBusinessDay
  for (let i = 1; i <= n; i += 1) {
    const date: Date = addDays(startDate, i)
    if (cantSelectDate(date, listHolidays)) n += 1
  }
  return addDays(startDate, n)
}

const getEarliestPaymentDateGivenActivationDate = (activationDate: Date, listHolidays: Date[]) => {
  const initialEarliestDateForFirstPayment = nthBusinessDayAfterDate(activationDate, 5, listHolidays)
  let earliestDayForFirstPayment: number = initialEarliestDateForFirstPayment.getDate()

  earliestDayForFirstPayment = possibleFirstPaymentDates.find((day) => day >= earliestDayForFirstPayment) ?? 0

  if (earliestDayForFirstPayment > 0) {
    return new Date(
      initialEarliestDateForFirstPayment.getFullYear(),
      initialEarliestDateForFirstPayment.getMonth(),
      earliestDayForFirstPayment,
    )
  }

  return new Date(
    initialEarliestDateForFirstPayment.getFullYear(),
    initialEarliestDateForFirstPayment.getMonth() + 1,
    possibleFirstPaymentDates[0],
  )
}

const rotateListFromGivenValue = (listToRotate: Array<number>, rotateFrom: number) => {
  const index: number = listToRotate.indexOf(rotateFrom)
  return listToRotate.slice(index, index + listToRotate.length).concat(listToRotate.slice(0, index))
}
export const computeFirstPaymentDateOptions = (activationDate: string, listHolidays: Date[]) => {
  const activationDateAsDate = parse(activationDate ?? formatDate(new Date()), 'yyyy-MM-dd', new Date())
  const earliestPaymentDate = getEarliestPaymentDateGivenActivationDate(activationDateAsDate, listHolidays)
  const earliestPaymentDay = earliestPaymentDate.getDate()
  const rotatedListOfOptions = rotateListFromGivenValue(possibleFirstPaymentDates, earliestPaymentDay)
  rotatedListOfOptions.push(rotatedListOfOptions[0])
  const firstPaymentDateOptionsList: Array<Date> = []

  rotatedListOfOptions.forEach((day) => {
    let firstPaymentDate = setDate(earliestPaymentDate, day)
    firstPaymentDate = firstPaymentDateOptionsList.some((date) => date.getTime() > firstPaymentDate.getTime())
      ? addMonths(firstPaymentDate, 1)
      : firstPaymentDate
    firstPaymentDateOptionsList.push(firstPaymentDate)
  })
  return firstPaymentDateOptionsList.filter((option) => isValid(option))
}

export const getFormattedPlan = (plan: MerchantPaymentPlan | undefined) => {
  if (plan) {
    return `${plan?.reducedInterestRate}%/${plan?.reducedRateDurationInMonths} → ${plan?.interestRate}%/${subtract(
      plan.loanTerm,
      plan.reducedRateDurationInMonths,
    )}`
  }
  return ''
}
export const isVariableInterest = (paymentPlanId: string): boolean => {
  return Boolean(paymentPlanId) && paymentPlanId !== EPaymentPlan.regularDailyInterests.toString()
}

export const getTotalObligation = (
  amountRequested: number,
  computedPersonalLoanFunding: ComputeFundingResultDto | null,
) => {
  return (
    amountRequested +
    (computedPersonalLoanFunding?.totalInterestAmount ?? 0) +
    (computedPersonalLoanFunding?.lenderFee ?? 0) +
    (computedPersonalLoanFunding?.insuranceFee ?? 0) +
    (computedPersonalLoanFunding?.insuranceTax ?? 0)
  )
}
export const getPersonalFundingDto = (
  creditApplicationId: string,
  amountRequested: number,
  paymentFrequency: EFrequencyType,
  term: number,
  deliveryOn: string,
  firstPaymentOn: Date,
  includeInsurance: boolean,
  stateIso: EProvince | null | undefined,
  paymentPlanId: string,
  interestRate: number,
) => {
  return {
    creditApplicationId,
    amountRequested,
    paymentFrequency,
    term,
    deliveryOn,
    firstPaymentOn,
    includeInsurance,
    stateIso,
    paymentPlanId,
    interestRate,
  } as ComputeFundingDto
}
export const getSelectedPlan = (merchant: Merchant, paymentPlanId: EPaymentPlan) => {
  return merchant?.paymentPlans?.find((p) => p.id === paymentPlanId)
}

export const isComputePersonalLoanDtoValid = (computePersonalLoanDto: ComputeFundingDto) => {
  try {
    worksheetDetailsSchema.validateSync(computePersonalLoanDto)
    return true
  } catch (error) {
    reportErrorToConsole(error)
    return false
  }
}

export const isComputePossibleTermsDtoValid = (monthTermFrequencyDto: ComputeMonthTermFrequencyDto) => {
  try {
    computePossibleTermsDtoSchema.validateSync(monthTermFrequencyDto)
    return true
  } catch (error) {
    reportErrorToConsole(error)
    return false
  }
}

export const getMerchantFees = (merchantFeeRate: number | undefined, amountRequested: number) => {
  if (merchantFeeRate) return FormatCurrency((merchantFeeRate / 100) * amountRequested)
  return ''
}
