import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { EFinancingProgram } from '@src/types/Constants'
import BrowseCreditApplicationPage from '../BrowseCreditApplicationPage'
import CreateCreditApplicationPage from '../CreditApplicationPage/CreateCreditApplicationPage'
import EditCreditApplicationPage from '../CreditApplicationPage/EditCreditApplicationPage'
import FundingSummary from '../FundingSummary'
import TemplateApp from './TemplateApp'
import ViewCreditApplicationPage from '../ViewCreditApplicationPage'
import CalculatorPage from '../CalculatorPage'
import WorksheetWrapper from '../CreateWorksheetPage/WorksheetWrapper'

const DetailCreditApplicationSubRouter = ({ financingProgramId }: { financingProgramId: EFinancingProgram }) => {
  const { id } = useParams<{ id: string }>()
  if (!id) return <Navigate to="/" />
  return (
    <Routes>
      <Route path="" element={<ViewCreditApplicationPage financingProgramId={financingProgramId} id={id} />} />
      <Route path="edit" element={<EditCreditApplicationPage financingProgramId={financingProgramId} id={id} />} />
      <Route path="worksheet" element={<WorksheetWrapper financingProgramId={financingProgramId} id={id} />} />
      <Route path="funding-summary" element={<FundingSummary financingProgramId={financingProgramId} id={id} />} />
    </Routes>
  )
}

const CreditApplicationSubRouter = () => {
  const { financingProgramId } = useParams<{ financingProgramId: EFinancingProgram }>()
  if (!financingProgramId) return <Navigate to="/" />
  return (
    <Routes>
      <Route path="add" element={<CreateCreditApplicationPage />} />
      <Route path=":id/*" element={<DetailCreditApplicationSubRouter financingProgramId={financingProgramId} />} />
    </Routes>
  )
}

const TopLevelRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<TemplateApp />}>
        <Route path="/credits" element={<BrowseCreditApplicationPage />} />
        <Route path="/calculator" element={<CalculatorPage />} />
        <Route path="/credits/:financingProgramId/*" element={<CreditApplicationSubRouter />} />
      </Route>
      <Route path="/" element={<Navigate to="/credits" replace />} />
      <Route path="/auth-page" element={<Navigate to="/" replace />} />
      <Route path="*" element={<Navigate to="/credits" replace />} />
    </Routes>
  )
}

export default TopLevelRoutes
