import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ProductsProvider } from '@src/types/ProductsProvider'
import { getApiClient } from '../api-client'

const SCOPE = 'products'
const LIST = 'list'
const DETAIL = 'detail'
const SCOPEPROVIDER = 'provider'

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  allLists: () => [{ scope: SCOPE, entity: LIST }] as const,
  allDetails: () => [{ scope: SCOPE, entity: DETAIL }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
  allProviderLists: () => [{ scope: SCOPEPROVIDER, entity: LIST }] as const,
}

const getProductsProviders = async ({
  // eslint-disable-next-line no-empty-pattern
  queryKey: [],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['allProviderLists']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get('/ProductProvider')
  return response.data as ProductsProvider[]
}

export default function useProductsProviders(): [ProductsProvider[], boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.allProviderLists(),
    queryFn: getProductsProviders,
  })
  return [data ?? [], isFetching]
}
