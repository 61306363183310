import { ProductsInsurance, ProductsWorksheet } from '../../../types/WorksheetSchema'

export function transformProdutsInsurancesToApi(worksheet: ProductsWorksheet): ProductsWorksheet {
  const anyFieldProvided = (values: ProductsInsurance | null) =>
    values !== null && (!!values.amount || !!values.coverageStartDate || !!values.term || !!values.police)

  const ret = worksheet

  ret.replacementOrGapInsurance = anyFieldProvided(ret.replacementOrGapInsurance) ? ret.replacementOrGapInsurance : null
  ret.creditInsurance = anyFieldProvided(ret.creditInsurance) ? ret.creditInsurance : null
  ret.extendedWarranty = anyFieldProvided(ret.extendedWarranty) ? ret.extendedWarranty : null

  return ret
}

export default { transformProdutsInsurancesToApi }
