import { MutationFunction, useMutation, useQueryClient } from '@tanstack/react-query'
import { EContractStatus, EFinancingProgram } from '@src/types/Constants'
import { FilteredWorksheet } from '@src/types/FilteredWorksheet'
import { ProductsWorksheet } from '@src/types/WorksheetSchema'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { AxiosError } from 'axios'
import { getApiClient } from '../api-client'

import { transformProdutsInsurancesToApi } from './worksheet-transform'

const SCOPE = 'worksheet'
const CREDIT_APP_SCOPE = 'credit-applications'
const DETAIL = 'detail'
const keysFactory = {
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
  creditAppDetail: (id: string, financingProgramId = EFinancingProgram.Products) =>
    [{ scope: CREDIT_APP_SCOPE, entity: DETAIL, id, financingProgramId }] as const,
}

const postProductsWorksheet: MutationFunction<FilteredWorksheet, { worksheet: ProductsWorksheet }> = async ({
  worksheet,
}) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<FilteredWorksheet>(
    `/Worksheet/${EFinancingProgram.Products}/${encodeURIComponent(worksheet.creditApplicationId!)}`,
    transformProdutsInsurancesToApi(worksheet),
  )

  return response.data
}

export function usePostProductsWorksheet(): [
  MutationFunction<FilteredWorksheet, { worksheet: ProductsWorksheet }>,
  boolean,
  boolean,
  AxiosError<{ message: string }> | null,
  () => void,
] {
  const queryClient = useQueryClient()
  const { isPending, isError, mutateAsync, error, reset } = useMutation({
    mutationFn: postProductsWorksheet,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.creditAppDetail(data.creditApplicationId), (oldData: CreditApplication) => {
        return {
          ...oldData,
          worksheet: data,
          updatedOn: data.updatedOn,
          contract: { ...oldData.contract, status: EContractStatus.notReady },
        }
      })
    },
  })

  return [mutateAsync, isPending, isError, error as AxiosError<{ message: string }>, reset]
}

const putProductsWorksheet: MutationFunction<FilteredWorksheet, { worksheet: ProductsWorksheet }> = async ({
  worksheet,
}) => {
  const apiClient = getApiClient()
  const response = await apiClient.put<FilteredWorksheet>(
    `/Worksheet/${EFinancingProgram.Products}/${encodeURIComponent(worksheet.creditApplicationId!)}`,
    transformProdutsInsurancesToApi(worksheet),
  )
  return response.data
}

export function usePutProductsWorksheet(): [
  MutationFunction<FilteredWorksheet, { worksheet: ProductsWorksheet }>,
  boolean,
  boolean,
  AxiosError<{ message: string }> | null,
  () => void,
] {
  const queryClient = useQueryClient()
  const { isPending, isError, error, mutateAsync, reset } = useMutation({
    mutationFn: putProductsWorksheet,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.creditAppDetail(data.creditApplicationId), (oldData: CreditApplication) => {
        return {
          ...oldData,
          worksheet: data,
          updatedOn: data.updatedOn,
          contract: { ...oldData.contract, status: EContractStatus.notReady },
        }
      })
    },
  })

  return [mutateAsync, isPending, isError, error as AxiosError<{ message: string }>, reset]
}
