// doit être fait en deux lignes sinon webpack optimise et n'inclut pas la string

import { PaletteColorOptions } from '@mui/material'
import { blue } from '@mui/material/colors'

// de plus, le toString est requis car en local c'est un string alors qu'en dev c'est une string
const useDevToolsStr = import.meta.env.VITE_USE_DEV_TOOLS ?? 'false'
export const useDevTools = useDevToolsStr.toString() === 'true'

export const HOME_PAGE = '/'
export const PREFERS_MODE_SETTING = 'themeMode'

export const CLEAR_CACHE_EXCEPTION_KEY = [PREFERS_MODE_SETTING]

export enum EServiceCategory {
  Veterinary = 'veterinary',
  Medical = 'medical',
  Aesthetics = 'aesthetics',
  Dentistry = 'dentistry',
  GoodsAndServices = 'goodsAndServices',
}

export enum EMerchantType {
  Merchant = 0,
  Banner = 1,
}

export enum EThemeMode {
  Dark = 'dark',
  Light = 'light',
}

export enum EFinancingProgram {
  Auto = 'auto',
  IfXpress = 'ifxpress',
  Vlvr = 'vlvr',
  Products = 'products',
  Personal = 'personal',
}

export enum EHomeStatus {
  Owner = 'own',
  Renter = 'rent',
  OwnMobileHome = 'ownMobileHome',
  WithParents = 'withParents',
  ReserveHousing = 'reserveHousing',
  OwnWithMortgage = 'ownWithMortgage',
  Other = 'other',
}
export const EHomeStatusList = Object.values(EHomeStatus)

export enum EOtherIncomeType {
  familyAllocations = 1,
  workIncidentIndemnisation = 3,
  maternityPaternityIncome = 4,
  other = 6,
  retirementIncome = 8,
  disabilityIncome = 11,
  incomeServiceAdjustment = 12,
  assistance = 13,
  employmentInsurance = 14,
}
export const EOtherIncomeTypeList = Object.values(EOtherIncomeType).filter(
  (x) => typeof x === 'number',
) as EOtherIncomeType[]

export enum ECreditApplicationStatus {
  Draft = 'draft',
  Active = 'active',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Expired = 'expired',
  Declined = 'declined',
  ManualRevision = 'manualRevision',
  DocumentsRequired = 'documentsRequired',
  Error = 'applicationError',
  Progress = 'inProgress',
  Waiting = 'waiting',
  Processing = 'processing',
  ActionRequired = 'actionRequired',
  AwaitingSignature = 'awaitingSignature',
  AwaitingApproval = 'awaitingApproval',
}

export enum EBeneficiaryType {
  NotSet = 0,
  Applicant = 1,
  Coapplicant = 2,
  Other = 3,
}

export enum EOriginSystemId {
  Backoffice = 'backoffice',
  Subscription = 'ifinancesub',
  MerchantDashboard = 'merchdb',
}

export enum EDecision {
  Pending = 'pending',
  Accept = 'approved',
  ManualRevision = 'manual-revision',
  Refused = 'refused',
  Error = 'error',
  None = '',
}

export enum EProvince {
  quebec = 'QC',
  ontario = 'ON',
  novaScotia = 'NS',
  newBrunswick = 'NB',
  princeEdwardIsland = 'PE',
  newfoundland = 'NL',
  britishColombia = 'BC',
  saskatchewan = 'SK',
  alberta = 'AB',
  manitoba = 'MB',
  yukon = 'YT',
  nunavut = 'NU',
  northwestTerritories = 'NT',
}
export const EProvinceList = Object.values(EProvince)

export enum EMask {
  phoneNumberMask = '[1-]000-000-0000',
  sinMask = '000 000 000',
  postalCode = 'a0a 0a0',
}

export enum EGender {
  men = 0,
  woman = 1,
  other = 2,
}

export const EGenderList = Object.values(EGender).filter((x) => typeof x === 'number')

export enum EMaritalStatus {
  single = 0,
  married = 1,
  deFactoCouple = 2,
  widowWidower = 3,
  undefined = 999,
}
export const EMaritalStatusList = Object.values(EMaritalStatus).filter((x) => typeof x === 'number')

export enum ERelationToApplicant {
  Spouse = 'SP',
  CommonLaw = 'CL',
  Employer = 'Employer',
  InLaw = 'IL',
  Parental = 'PR',
  GrandMother = 'GM',
  GrandFather = 'GF',
  Brother = 'B',
  Sister = 'S',
  Other = 'Other',
}
export const ERelationToApplicantList = Object.values(ERelationToApplicant)

export enum EJobType {
  fullTime = 0,
  partTime = 1,
  Seasonal = 3,
  SelfEmployed = 5,
}
export const EJobTypeList = Object.values(EJobType).filter((x) => typeof x === 'number') as EJobType[]

export enum EDocumentStatus {
  AwaitingDocument = 'awaitingDocument',
  AwaitingScan = 'awaitingScan',
  AwaitingApproval = 'awaitingApproval',
  Incomplete = 'incomplete',
  Deleted = 'deleted',
  Refused = 'refused',
  Approved = 'approved',
}

export enum ELanguage {
  French = 0,
  English = 1,
}

export const ELanguageList = Object.values(ELanguage).filter((x) => typeof x === 'number')

export enum EIconType {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export enum EProductsInsurance {
  CreditInsurance = 'creditInsurance',
  ReplacementOrGapInsurance = 'replacementOrGapInsurance',
  ExtendedWarranty = 'extendedWarranty',
}

export enum EApplicantType {
  Applicant = 'applicant',
  Coapplicant = 'coApplicant',
}

export enum ECreditApplicationStep {
  Credit = 'credit',
  Financing = 'financing',
  CVT = 'CVT',
  PayOut = 'payout',
}

export enum ECurrentCustomerStep {
  Draft = 'draft',
  Prequal = 'prequal',
  Credit = 'credit',
  IncomeVerification = 'incomeVerification',
  Contract = 'contract',
  Funding = 'funding',
}

export enum IconType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
  waiting = 'waiting',
}

export const MinimumAgeRequirementByStateIso = {
  quebec: 18,
  ontario: 18,
  novaScotia: 19,
  newBrunswick: 19,
  princeEdwardIsland: 18,
  newfoundland: 19,
  britishColombia: 18,
  saskatchewan: 18,
  alberta: 18,
  manitoba: 18,
  yukon: 18,
  nunavut: 18,
  northwestTerritories: 18,
}

export enum EContractStatus {
  ready = 'ready',
  sending = 'sending',
  declined = 'declined',
  notReady = 'notready',
  underReview = 'review',
  completed = 'completed',
}
export enum EWorksheetStatus {
  Draft = 'draft',
  Active = 'active',
  Completed = 'completed',
}

export enum PaymentMethod {
  check = 'check',
  bankTransfer = 'bankTransfer',
  DEFT = 'DEFT',
  WIRE = 'Wire',
}
export enum EPaymentPlan {
  regularDailyInterests = 'regularInterest',
}
export enum MerchantId {
  IfinanceMerchantId = 'alis*20500',
  MedicardMerchantId = 'alis*20878',
}

export enum ECompany {
  Iceberg = 'Iceberg',
  IFinance = 'IFinance',
}

export enum EDocType {
  signedCvt = 47,
  ExtendedWarrantyContract = 15,
  LastPayStubJob = 17,
  SixMonthsPersonalBankStatements = 36,
  VehiclePurchaseContract = 42,
  VoidCheque = 44,
  FamilyAllocationsProof = 68,
  WorkIncidentIndemnisationProof = 69,
  MaternityPaternityIncomeProof = 70,
  OtherIncomeProof = 71,
  RetirementIncomeProof = 72,
  DisabilityIncomeProof = 73,
  AssistanceProof = 74,
  EmployementInsuranceProof = 75,
  ValidPhotoId = 67,
  CreditInsuranceContract = 81,
  ReplacementOrGapInsuranceContract = 82,
}

export enum MerchantNotificationCode {
  HardHitMissing = 'HardHitMissing',
  IncomeVerificationMissing = 'IncomeVerificationMissing',
  ContractMissing = 'ContractMissing',
}

export enum EFrequencyType {
  Monthly = 'monthly',
  BiWeekly = 'biWeekly',
}

export enum ETerms {
  twelveMonths = 12,
  eighteenMonths = 18,
  twentyFourMonths = 24,
  thirtyMonths = 30,
  thirtySixMonths = 36,
  fortyTwoMonths = 42,
  fortyEightMonths = 48,
  fiftyFourMonths = 54,
  sixtyMonths = 60,
  sixtySixMonths = 66,
  seventyTwoMonths = 72,
}

export const ETermsList: ETerms[] = Object.values(ETerms).filter((value) => typeof value === 'number') as ETerms[]

export const HiddenMerchantCategories = [
  EServiceCategory.Dentistry,
  EServiceCategory.Medical,
  EServiceCategory.Aesthetics,
]

export interface FinancingProgramConfigs {
  useSoftHit: boolean
  useHardHit: boolean
  benefyciaryIsRequired: boolean
  requestedLoanAmountIsRequired: boolean
  loanPurposeIdIsRequired: boolean
  useHardHitForSpecificProvince: EProvince[] | null
  useAlwaysSubmit: boolean
  displayPrequalify: boolean
  displayIncomeVerification: boolean
  minIncome: number
  minCombinedIncome: number
  frequencyPayments: EFrequencyType[]
}

export type CompanyThemeModeOptions = {
  logo: string
  logoContrast: string
  logoAlt: string
  favicon: string
  primary: PaletteColorOptions
  secondary: PaletteColorOptions
}

export interface FinancingCompanyConfigOptions {
  name: string
  supportedPrograms: EFinancingProgram[]
  privacyPolicyLinkEN: string
  privacyPolicyLinkFR: string
  phone: string
  theme: Record<EThemeMode, CompanyThemeModeOptions>
}

export const defaultProgramConfig: FinancingProgramConfigs = {
  useSoftHit: false,
  useHardHit: false,
  benefyciaryIsRequired: false,
  requestedLoanAmountIsRequired: false,
  loanPurposeIdIsRequired: false,
  useHardHitForSpecificProvince: null,
  useAlwaysSubmit: false,
  minIncome: 21000,
  minCombinedIncome: 35000,
  displayIncomeVerification: true,
  displayPrequalify: true,
  frequencyPayments: Object.values(EFrequencyType).filter((item) => item === EFrequencyType.Monthly),
}

export const FinancingCompanyConfigs: Record<ECompany, FinancingCompanyConfigOptions> = {
  [ECompany.Iceberg]: {
    name: 'Iceberg',
    supportedPrograms: [EFinancingProgram.Auto, EFinancingProgram.Vlvr, EFinancingProgram.Products],
    privacyPolicyLinkEN: 'https://icebergfinance.ca/en/privacy-policy',
    privacyPolicyLinkFR: 'https://icebergfinance.ca/politique-de-confidentialite',
    phone: '1-888-694-0960',
    theme: {
      [EThemeMode.Light]: {
        logo: '/images/iceberg.svg',
        logoContrast: '/images/iceberg-white.svg',
        logoAlt: 'Iceberg Finance',
        favicon: '/images/favicon-iceberg.png',
        primary: {
          main: '#988c60',
        },
        secondary: {
          main: '#131212',
        },
      },
      [EThemeMode.Dark]: {
        logo: '/images/iceberg-white.svg',
        logoContrast: '/images/iceberg-white.svg',
        logoAlt: 'Iceberg Finance',
        favicon: '/images/favicon-iceberg.png',
        primary: {
          main: '#988c60',
        },
        secondary: {
          main: '#131212',
        },
      },
    },
  },
  [ECompany.IFinance]: {
    name: 'Ifinance',
    supportedPrograms: [EFinancingProgram.Personal, EFinancingProgram.IfXpress],
    privacyPolicyLinkEN: 'https://ifinancecanada.com/privacy-policy',
    privacyPolicyLinkFR: 'https://ifinancecanada.com/fr/politique-de-confidentialite',
    phone: '1-888-689-9876',
    theme: {
      [EThemeMode.Light]: {
        logo: '/images/iFinance.svg',
        logoContrast: '/images/iFinance-white.svg',
        logoAlt: 'iFinance',
        favicon: '/images/favicon-ifinance.png',
        primary: {
          main: blue[700],
        },
        secondary: {
          main: '#14233a',
        },
      },
      [EThemeMode.Dark]: {
        logo: '/images/iFinance-white.svg',
        logoContrast: '/images/iFinance-white.svg',
        logoAlt: 'iFinance',
        favicon: '/images/favicon-ifinance.png',
        primary: {
          main: blue[200],
        },
        secondary: {
          main: '#14233a',
        },
      },
    },
  },
}

export const Constants = {
  SystemUserId: 'system',
  MinimumLoanAmount: 500,
  MaximumLoanAmount: 40000,
  LargeLoanAmount: 10000,
  LongTerm: 84,
  ProductMinimumLoanAmount: 750,
  SupportedDocumentTypes: '.jpg, .jpeg, .png, .pdf, .heic, .heif',
  MaxYearsToDisplay: 125,
  financingProgramConfigs: {
    [EFinancingProgram.Auto]: {
      ...defaultProgramConfig,
      useHardHit: true,
      frequencyPayments: Object.values(EFrequencyType).filter((item) => item === EFrequencyType.Monthly),
    },
    [EFinancingProgram.Products]: {
      ...defaultProgramConfig,
      minCombinedIncome: 0,
      useAlwaysSubmit: true,
      useHardHitForSpecificProvince: [EProvince.quebec],
      displayPrequalify: false,
      displayIncomeVerification: false,
      frequencyPayments: Object.values(EFrequencyType),
    },
    [EFinancingProgram.Personal]: {
      ...defaultProgramConfig,
      useSoftHit: true,
      useHardHit: true,
      benefyciaryIsRequired: true,
      requestedLoanAmountIsRequired: true,
      loanPurposeIdIsRequired: true,
      frequencyPayments: Object.values(EFrequencyType).filter((item) => item === EFrequencyType.Monthly),
    },
    [EFinancingProgram.Vlvr]: {
      ...defaultProgramConfig,
      minIncome: 20000,
      frequencyPayments: Object.values(EFrequencyType).filter((item) => item === EFrequencyType.Monthly),
    },
  } as Record<string, FinancingProgramConfigs>,
}
