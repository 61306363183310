import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { Link } from 'react-router-dom' // Optional, if using React Router
import { useTranslation } from 'react-i18next'

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
      sx={{ textAlign: 'center' }}
    >
      <Typography variant="h1" component="h1" color="primary">
        {t('common.404NotFound')}
      </Typography>
      <Typography variant="h6" color="textSecondary" sx={{ marginBottom: 3 }}>
        {t('common.sorry')}
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/">
        {t('common.goHome')}
      </Button>
    </Box>
  )
}

export default NotFound
