import React from 'react'
import { Grid } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { ApplicantJob, JobSchema } from '@src/types/CreditApplicationSchema'
import { Address } from '@src/types/AddressSchema'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import InputTextFieldWithMask from '@src/components/InputMask'
import ActionsDialog from '@src/components/ActionsDialog'
import { EJobTypeList, EMask } from '@src/types/Constants'
import AddressComponent from './AddressComponent'

type Props = {
  open: boolean
  title: string
  defaultValue: ApplicantJob
  onConfirm: (data: ApplicantJob) => void
  onCancel: () => void
}

const EditJobDialog = ({ defaultValue, onConfirm, onCancel, open, title }: Props) => {
  const { t } = useTranslation()

  const {
    register,
    trigger,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm<ApplicantJob>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: defaultValue,
    resolver: yupResolver(JobSchema),
  })

  React.useEffect(() => {
    reset(defaultValue)
  }, [defaultValue, reset])

  const isAddressEmpty = (address: Address | null) => {
    return Boolean(address) && Object.values(address!).every((a) => a === null || a === undefined)
  }

  const handleSubmit = async () => {
    await trigger(['annualSalary', 'jobType', 'jobTitle', 'employerName', 'employerPhone', 'years', 'months']).then(
      (isValid) => {
        if (isValid) {
          const jobCopy = { ...getValues() }
          if (isAddressEmpty(jobCopy.address)) jobCopy.address = null
          onConfirm(jobCopy)
        }
      },
    )
  }

  return (
    <ActionsDialog title={title} open={open} onCancel={onCancel} onConfirm={handleSubmit}>
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <InputTextField
            id="annualSalary"
            type="number"
            InputProps={{ inputProps: { inputMode: 'numeric' } }}
            error={errors?.annualSalary}
            label={t('editCreditApplication.income.job.annualSalary')}
            {...register('annualSalary')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SelectComponent
            valueList={EJobTypeList.map((value) => ({ label: t(`jobType.${value}`), value }))}
            label={t('editCreditApplication.income.job.jobType') as string}
            {...register('jobType')}
            error={errors?.jobType}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputTextField
            id="jobTitle"
            inputProps={{ maxLength: 50 }}
            error={errors?.jobTitle}
            label={t('editCreditApplication.income.job.jobTitle')}
            {...register('jobTitle')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputTextField
            id="employerName"
            error={errors?.employerName}
            inputProps={{ maxLength: 100 }}
            label={t('editCreditApplication.income.job.employerName')}
            {...register('employerName')}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={4} lg={5}>
          <Controller
            name="employerPhone"
            control={control}
            render={({ field }) => (
              <InputTextFieldWithMask
                id="employerPhone"
                mask={EMask.phoneNumberMask}
                error={errors?.employerPhone}
                label={t('editCreditApplication.income.job.employerPhone')}
                {...field}
                onChange={(e) => field.onChange(e.target.value.substring(1).replace(/-/g, ''))}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={1}>
          <InputTextField
            id="employerPhoneExt"
            inputProps={{ maxLength: 10, inputMode: 'numeric' }}
            error={errors?.employerPhoneExt}
            label={t('editCreditApplication.income.job.employerPhoneExt')}
            {...register('employerPhoneExt')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputTextField
            id="jobSinceYears"
            type="number"
            inputProps={{ inputMode: 'numeric' }}
            error={errors?.years}
            label={t('editCreditApplication.address.since.years')}
            {...register('years')}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputTextField
            id="jobSinceMonths"
            type="number"
            inputProps={{ inputMode: 'numeric' }}
            error={errors?.months}
            label={t('editCreditApplication.address.since.months')}
            {...register('months')}
          />
        </Grid>
        <Grid item xs={12}>
          <AddressComponent
            formControl={control}
            register={register}
            errors={errors?.address}
            isCommercial
            name="address"
            includeDuration={false}
          />
        </Grid>
      </Grid>
    </ActionsDialog>
  )
}

export default React.memo(EditJobDialog)
