import { MutationFunction, QueryFunctionContext, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { EContractStatus, EFinancingProgram } from '@src/types/Constants'
import { ComputeFundingDto } from '@src/types/ComputePersonalLoanFundingDto'
import { ComputeFundingResultDto } from '@src/types/ComputePersonalLoanFundingResultDto'
import { isComputePersonalLoanDtoValid } from '@src/data/worksheet-selectors'
import { FilteredWorksheet } from '@src/types/FilteredWorksheet'
import { EditPersonalLoanWorksheetDto } from '@src/types/WorksheetSchema'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { AxiosError } from 'axios'
import { getApiClient } from '../api-client'

const SCOPE = 'worksheet'
const CREDIT_APP_SCOPE = 'credit-applications'
const SCOPEPERSOFUNDING = 'personalFunding'
const DETAIL = 'detail'
const keysFactory = {
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
  personalFunding: (id: string, dto: ComputeFundingDto) => [{ scope: SCOPEPERSOFUNDING, id, ...dto }] as const,

  creditAppDetail: (id: string, financingProgramId = EFinancingProgram.Personal) =>
    [{ scope: CREDIT_APP_SCOPE, entity: DETAIL, id, financingProgramId }] as const,
}

const GetPersonalFunding = async ({
  queryKey: [{ id, ...dto }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['personalFunding']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<ComputeFundingResultDto>(
    `/Worksheet/${EFinancingProgram.Personal}/${encodeURIComponent(id)}/ComputeFunding`,
    dto,
  )
  return response.data
}

export function useGetPersonalFunding(id: string, dto: ComputeFundingDto): [ComputeFundingResultDto, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.personalFunding(id, dto),
    queryFn: GetPersonalFunding,
    enabled: isComputePersonalLoanDtoValid(dto),
  })

  return [data!, isFetching]
}

const postPersonalLoanWorksheet: MutationFunction<
  FilteredWorksheet,
  { worksheet: EditPersonalLoanWorksheetDto }
> = async ({ worksheet }) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<FilteredWorksheet>(
    `/Worksheet/${EFinancingProgram.Personal}/${encodeURIComponent(worksheet.creditApplicationId!)}`,
    worksheet,
  )

  return response.data
}

export function usePostPersonalLoanWorksheet(): [
  MutationFunction<FilteredWorksheet, { worksheet: EditPersonalLoanWorksheetDto }>,
  boolean,
  boolean,
  AxiosError<{ message: string }> | null,
  () => void,
] {
  const queryClient = useQueryClient()
  const { isPending, isError, mutateAsync, error, reset } = useMutation({
    mutationFn: postPersonalLoanWorksheet,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.creditAppDetail(data.creditApplicationId), (oldData: CreditApplication) => {
        return {
          ...oldData,
          worksheet: data,
          updatedOn: data.updatedOn,
          contract: { ...oldData.contract, status: EContractStatus.notReady },
        }
      })
    },
  })

  return [mutateAsync, isPending, isError, error as AxiosError<{ message: string }>, reset]
}

const putPersonalLoanWorksheet: MutationFunction<
  FilteredWorksheet,
  { worksheet: EditPersonalLoanWorksheetDto }
> = async ({ worksheet }) => {
  const apiClient = getApiClient()
  const response = await apiClient.put<FilteredWorksheet>(
    `/Worksheet/${EFinancingProgram.Personal}/${encodeURIComponent(worksheet.creditApplicationId!)}`,
    worksheet,
  )
  return response.data
}

export function usePutPersonalLoanWorksheet(): [
  MutationFunction<FilteredWorksheet, { worksheet: EditPersonalLoanWorksheetDto }>,
  boolean,
  boolean,
  AxiosError<{ message: string }> | null,
  () => void,
] {
  const queryClient = useQueryClient()
  const { isPending, isError, error, mutateAsync, reset } = useMutation({
    mutationFn: putPersonalLoanWorksheet,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.creditAppDetail(data.creditApplicationId), (oldData: CreditApplication) => {
        return {
          ...oldData,
          worksheet: data,
          updatedOn: data.updatedOn,
          contract: { ...oldData.contract, status: EContractStatus.notReady },
        }
      })
    },
  })

  return [mutateAsync, isPending, isError, error as AxiosError<{ message: string }>, reset]
}
