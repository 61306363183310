import { Box, Divider, Stack, Typography } from '@mui/material'
import SelectComponent from '@src/components/SelectComponent'
import StepStatusSection from '@src/containers/App/components/StepStatusSection'
import { t } from 'i18next'
import { ProductsProvider } from '@src/types/ProductsProvider'
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form'
import { ProductsInsurance, ProductsWorksheet } from '@src/types/WorksheetSchema'
import { ChangeEvent, useEffect, useState } from 'react'
import { EProductsInsurance, EProvince } from '@src/types/Constants'
import ProductsInsuranceDetails from './ProductsInsuranceDetails'

interface Props {
  insurancesProvider: ProductsProvider[]
  warrantyProvider: ProductsProvider[]
  register: UseFormRegister<ProductsWorksheet>
  setValue: UseFormSetValue<ProductsWorksheet>
  control: Control<ProductsWorksheet>
  errors: FieldErrors<ProductsWorksheet>
  watch: UseFormWatch<ProductsWorksheet>
  getValues: UseFormGetValues<ProductsWorksheet>
  trigger: UseFormTrigger<ProductsWorksheet>
  stateIso: EProvince
}

const ProductsInsurances = ({
  insurancesProvider,
  warrantyProvider,
  register,
  setValue,
  control,
  errors,
  watch,
  getValues,
  trigger,
  stateIso,
}: Props) => {
  let defaultInsuranceProvider: string | null = null

  getValues(['replacementOrGapInsurance', 'creditInsurance']).forEach((e) => {
    if (e?.provider && !defaultInsuranceProvider) {
      defaultInsuranceProvider = e?.provider
    }
  })

  const [insuranceProvider, setInsuranceProvider] = useState<string | null>(defaultInsuranceProvider)
  const [extendedWarrantyProvider, setExtendedWarrantyProvider] = useState<string | null>(
    getValues('extendedWarranty.provider'),
  )
  const [insuranceProviderError, setInsuranceProvideError] = useState<string | undefined>(undefined)
  const [extendedWarrantyError, setExtendedWarrantyError] = useState<string | undefined>(undefined)

  const [worksheet, setWorksheet] = useState<ProductsWorksheet>(getValues())
  const anyFieldProvided = (values: ProductsInsurance | null) => {
    if (!values) return false
    return !!values.amount || !!values.coverageStartDate || !!values.term || !!values.police
  }

  useEffect(() => {
    const validate = (key: EProductsInsurance, provider: string | null, fieldProvided: boolean) => {
      if (fieldProvided) {
        setValue(`${key}.provider`, provider)
      } else {
        setValue(`${key}.provider`, null)
        // eslint-disable-next-line no-void
        void trigger(key)
      }
    }

    const anyReplacementOrGapField = anyFieldProvided(worksheet.replacementOrGapInsurance)
    const anyCreditField = anyFieldProvided(worksheet.creditInsurance)
    const anyExtendedField = anyFieldProvided(worksheet.extendedWarranty)

    validate(EProductsInsurance.CreditInsurance, insuranceProvider, anyCreditField)

    validate(EProductsInsurance.ReplacementOrGapInsurance, insuranceProvider, anyReplacementOrGapField)

    validate(EProductsInsurance.ExtendedWarranty, extendedWarrantyProvider, anyExtendedField)

    const error =
      (anyReplacementOrGapField || anyCreditField) && !insuranceProvider ? 'common.errors.required' : undefined

    setInsuranceProvideError(error)

    setExtendedWarrantyError(anyExtendedField && !extendedWarrantyProvider ? 'common.errors.required' : undefined)

    const getPrime = (value: number | null | undefined) => {
      const amount = Number(value)
      return Number.isNaN(amount) ? 0 : amount
    }

    const amount =
      getPrime(worksheet.replacementOrGapInsurance?.amount) +
      getPrime(worksheet.creditInsurance?.amount) +
      getPrime(worksheet.extendedWarranty?.amount)

    setValue('amountRequested', amount)
  }, [insuranceProvider, extendedWarrantyProvider, worksheet, setValue, trigger, getValues])

  useEffect(() => {
    const { unsubscribe } = watch((values) => {
      setWorksheet(values as ProductsWorksheet)
    })
    return () => unsubscribe()
  }, [watch])

  return (
    <Stack direction={{ lg: 'row', md: 'column' }} spacing={2} justifyContent="space-between">
      <StepStatusSection title={t('productsWorksheet.insurances')}>
        <SelectComponent
          error={insuranceProviderError}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setInsuranceProvider(e.target.value)
          }}
          valueList={insurancesProvider.map((provider) => ({
            label: provider.name,
            value: provider.id,
          }))}
          defaultValue={defaultInsuranceProvider}
          label={t('common.provider') as string}
        />
        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
          <Box>
            <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 2, mt: 2 }}>
              {stateIso === EProvince.quebec
                ? t('productsWorksheet.replacementInsurance')
                : t('productsWorksheet.gapInsurance')}
            </Typography>
            <ProductsInsuranceDetails
              register={register}
              insurance={EProductsInsurance.ReplacementOrGapInsurance}
              control={control}
              errors={errors}
            />
          </Box>

          <Box>
            <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 2, mt: 2 }}>
              {t('productsWorksheet.creditInsurance')}
            </Typography>
            <ProductsInsuranceDetails
              register={register}
              insurance={EProductsInsurance.CreditInsurance}
              control={control}
              errors={errors}
            />
          </Box>
        </Stack>
      </StepStatusSection>
      <StepStatusSection title={t('productsWorksheet.extendedWarranty')}>
        <SelectComponent
          valueList={warrantyProvider.map((provider) => ({
            label: provider.name,
            value: provider.id,
          }))}
          error={extendedWarrantyError}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setExtendedWarrantyProvider(e.target.value)
          }}
          label={t('common.provider') as string}
        />
        <ProductsInsuranceDetails
          register={register}
          insurance={EProductsInsurance.ExtendedWarranty}
          control={control}
          errors={errors}
        />
      </StepStatusSection>
    </Stack>
  )
}

export default ProductsInsurances
