import { EFinancingProgram } from '@src/types/Constants'
import NotFound from '@src/components/NotFound'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { UserDtoFromJwt } from '@src/types/User'
import { Merchant } from '@src/types/Merchant'
import { getMerchantList, usePaymentPlanList } from '../ViewCreditApplicationPage/worksheet-hooks'
import PersonalLoanWorksheet from './PersonalLoanWorksheet'
import ProductsWorksheetPage from './CreateProductsWorksheetPage/ProductsWorksheet'

type Props = {
  creditApplication: CreditApplication
  user: UserDtoFromJwt
  merchant: Merchant
  merchantsListFromParentId: Merchant[]
  listHolidays: Date[]
}

const CreateWorksheetPage = ({ creditApplication, user, merchant, merchantsListFromParentId, listHolidays }: Props) => {
  const merchantPayments = usePaymentPlanList(creditApplication, merchant)
  const { merchantList, setMerchantList } = getMerchantList(
    creditApplication,
    merchantsListFromParentId,
    merchant,
    user,
  )

  if (creditApplication && merchant)
    switch (creditApplication.financingProgramId) {
      case EFinancingProgram.Personal:
        return (
          <PersonalLoanWorksheet
            listHolidays={listHolidays}
            merchant={merchant}
            creditApplication={creditApplication}
            user={user}
            merchantsListFromParentId={merchantsListFromParentId}
            merchantPayments={merchantPayments}
            merchantList={merchantList}
            setMerchantList={setMerchantList}
          />
        )

      case EFinancingProgram.Products:
        return (
          <ProductsWorksheetPage
            merchant={merchant}
            creditApplication={creditApplication}
            merchantPayments={merchantPayments}
          />
        )
      default:
        return <NotFound />
    }

  return <NotFound />
}

export default CreateWorksheetPage
