import {
  Constants,
  defaultProgramConfig,
  ECompany,
  ECreditApplicationStep,
  EDocType,
  EDecision,
  EDocumentStatus,
  EFinancingProgram,
  EOriginSystemId,
  EProvince,
  EWorksheetStatus,
  FinancingCompanyConfigs,
  HiddenMerchantCategories,
  MinimumAgeRequirementByStateIso,
} from '@src/types/Constants'
import type { CreditApplication } from '@src/types/CreditApplicationSchema'
import { RequiredDocument } from '@src/types/RequiredDocument'

export const programConfigByFinancingProgramId = (financingProgram: EFinancingProgram) => {
  return Constants.financingProgramConfigs[financingProgram] ?? defaultProgramConfig
}

export const companyConfigByFinancingProgramId = (financingProgram: EFinancingProgram) => {
  const icebergConfig = FinancingCompanyConfigs[ECompany.Iceberg]
  const ifinanceConfig = FinancingCompanyConfigs[ECompany.IFinance]

  if (icebergConfig.supportedPrograms.includes(financingProgram)) {
    return icebergConfig
  }
  return ifinanceConfig
}

export const anyDocsAwaitingApproval = (requiredDocuments: RequiredDocument[]) => {
  return requiredDocuments?.some((doc) => doc.status === EDocumentStatus.AwaitingApproval)
}

export const anyDocsAwaitingUploadOrRefused = (
  requiredDocuments: RequiredDocument[],
  step = ECreditApplicationStep.Credit,
) => {
  const steps = Object.values(ECreditApplicationStep)
  const stepIndex = steps.indexOf(step)

  return requiredDocuments?.some(
    (doc) =>
      (doc.status === EDocumentStatus.AwaitingDocument ||
        doc.status === EDocumentStatus.Incomplete ||
        doc.status === EDocumentStatus.Refused ||
        doc.status === EDocumentStatus.Deleted) &&
      steps.indexOf(doc.requiredBeforeStep) <= stepIndex,
  )
}

export const getCanEdit = (originSystemId: EOriginSystemId, isEditLocked: boolean) => {
  return originSystemId === EOriginSystemId.MerchantDashboard && !isEditLocked
}

export const getTimeStamp = () => {
  const now = new Date()

  const year = now.getUTCFullYear().toString()
  const month = (now.getUTCMonth() + 1).toString()
  const day = now.getUTCDate().toString()
  const hour = now.getUTCHours().toString()
  const minute = now.getUTCMinutes().toString()
  const second = now.getUTCSeconds().toString()

  return `${year}${month}${day}${hour}${minute}${second}`
}

export const CvtAwaitingApproval = (requiredDocuments: RequiredDocument[]) => {
  return requiredDocuments?.some(
    (doc) => doc.typeId === EDocType.signedCvt && doc.status === EDocumentStatus.AwaitingApproval,
  )
}

export const areAllRequiredDocumentForStepApproved = (
  creditApp: CreditApplication,
  step: ECreditApplicationStep,
): boolean => {
  return creditApp.requiredDocuments
    .filter((doc) => doc.requiredBeforeStep === step)
    .some((doc) => doc.status !== EDocumentStatus.Approved)
}

export const checkCanCreateContract = (creditApp: CreditApplication) => {
  const documentsRequireApprovalForCVT = areAllRequiredDocumentForStepApproved(creditApp, ECreditApplicationStep.CVT)

  return (
    creditApp.financingProgramId === EFinancingProgram.Products &&
    !documentsRequireApprovalForCVT &&
    !creditApp.hasTasksBeforeCvt &&
    creditApp.worksheet?.status === EWorksheetStatus.Active &&
    creditApp.worksheet?.finalDecision.decision !== EDecision.Accept
  )
}

export const shouldDisplayInterest = (creditApp: CreditApplication) => {
  if (creditApp.originSystemId !== EOriginSystemId.MerchantDashboard) return false
  return !HiddenMerchantCategories.includes(creditApp.loanPurposeId)
}

export function findMinimumAgeRequirement(stateIso: EProvince) {
  switch (stateIso) {
    case EProvince.quebec:
      return MinimumAgeRequirementByStateIso.quebec
    case EProvince.ontario:
      return MinimumAgeRequirementByStateIso.ontario
    case EProvince.novaScotia:
      return MinimumAgeRequirementByStateIso.novaScotia
    case EProvince.newBrunswick:
      return MinimumAgeRequirementByStateIso.newBrunswick
    case EProvince.princeEdwardIsland:
      return MinimumAgeRequirementByStateIso.princeEdwardIsland
    case EProvince.newfoundland:
      return MinimumAgeRequirementByStateIso.newfoundland
    case EProvince.britishColombia:
      return MinimumAgeRequirementByStateIso.britishColombia
    case EProvince.saskatchewan:
      return MinimumAgeRequirementByStateIso.saskatchewan
    case EProvince.alberta:
      return MinimumAgeRequirementByStateIso.alberta
    case EProvince.manitoba:
      return MinimumAgeRequirementByStateIso.manitoba
    case EProvince.yukon:
      return MinimumAgeRequirementByStateIso.yukon
    case EProvince.nunavut:
      return MinimumAgeRequirementByStateIso.nunavut
    case EProvince.northwestTerritories:
      return MinimumAgeRequirementByStateIso.northwestTerritories
    default:
      return 18
  }
}

export const availableCreditEqualApprovedAmount = (creditApp: CreditApplication) => {
  return creditApp.availableCreditAmount === creditApp.finalCreditDecision.maxAmountFinanced
}
