import { Alert, Button, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Info as InfoIcon, AccessTimeOutlined as AccessTimeOutlinedIcon } from '@mui/icons-material/'
import { IconType } from '@src/types/Constants'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import React from 'react'

type Color = 'success' | 'info' | 'warning' | 'error'

type Props = {
  iconMessage?: string
  iconType?: IconType | null
  onClick?: () => void
}
const IconSection = ({ iconMessage, iconType, onClick }: Props) => {
  let icon: JSX.Element | null
  let color: Color = 'info'
  switch (iconType) {
    case IconType.success:
      color = 'success'
      icon = <CheckCircleOutlineIcon />
      break
    case IconType.error:
      color = 'error'
      icon = <HighlightOffIcon />
      break
    case IconType.warning:
      color = 'warning'
      icon = <ErrorOutlineIcon />
      break
    case IconType.info:
      color = 'info'
      icon = <InfoIcon />
      break
    case IconType.waiting:
      color = 'info'
      icon = <AccessTimeOutlinedIcon />
      break
    default:
      icon = null
  }
  if (onClick)
    return (
      <Button
        onClick={onClick}
        variant="contained"
        color={color}
        sx={{
          borderRadius: 0,
          py: '13px',
          textTransform: 'none',
        }}
        startIcon={<HelpOutlineIcon />}
      >
        <Typography variant="body1" fontWeight="bold">
          {iconMessage}
        </Typography>
      </Button>
    )
  return (
    <Alert
      variant="filled"
      severity={color}
      icon={icon}
      sx={{ display: 'flex', justifyContent: 'center', borderRadius: 0 }}
    >
      <Typography variant="body1" fontWeight="bold">
        {iconMessage}
      </Typography>
    </Alert>
  )
}

export default IconSection
