import { InputAdornment, Stack } from '@mui/material'
import { DatePicker } from '@src/components/DatePicker'
import InputTextField from '@src/components/InputTextField'
import { EProductsInsurance } from '@src/types/Constants'
import { ProductsWorksheet } from '@src/types/WorksheetSchema'
import { t } from 'i18next'
import { Control, UseFormRegister, FieldErrors } from 'react-hook-form'

interface Props {
  register: UseFormRegister<ProductsWorksheet>
  insurance: EProductsInsurance
  control: Control<ProductsWorksheet>
  errors: FieldErrors<ProductsWorksheet>
}

const ProductsInsuranceDetails = ({ insurance: name, register, control, errors }: Props) => {
  return (
    <Stack spacing={2}>
      <InputTextField label="# Police" fullWidth {...register(`${name}.police`)} error={errors[name]?.police} />
      <InputTextField
        label="Term"
        error={errors[name]?.term}
        {...register(`${name}.term`)}
        InputProps={{
          endAdornment: <InputAdornment position="end">{t('common.month')}</InputAdornment>,
        }}
      />
      <InputTextField
        label="Prime (Tx incluses)"
        error={errors[name]?.amount}
        {...register(`${name}.amount`)}
        InputProps={{
          endAdornment: <InputAdornment position="end">$</InputAdornment>,
        }}
      />
      <DatePicker
        name={`${name}.coverageStartDate`}
        error={errors[name]?.coverageStartDate}
        label="Début de la couverture"
        disablePast
        minDate={new Date()}
        control={control}
      />
    </Stack>
  )
}

export default ProductsInsuranceDetails
