import { Grid } from '@mui/material'
import { DatePicker } from '@src/components/DatePicker'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import { useComputeFirstPaymentDateOptions } from '@src/containers/ViewCreditApplicationPage/worksheet-hooks'
import { programConfigByFinancingProgramId } from '@src/data/creditapp-selectors'
import { cantSelectDate, isVariableInterest } from '@src/data/worksheet-selectors'
import { formatDate } from '@src/services/Formatter'
import { EPaymentPlan } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import { WorsheetDetailsDto } from '@src/types/WorksheetSchema'
import { useCallback } from 'react'
import { Control, FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  listHolidays: Date[]
  creditApplication: CreditApplication
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>
  errors: FieldErrors<WorsheetDetailsDto>
  merchantPayments: { label: string; value: string; id: string }[]
  disabledAmountInput?: boolean
  maxTermDuration?: number
  loanTermChoicesList: Array<SelectValueListItem>
  isLoadingTerm: boolean
}

const WorksheetDetails = ({
  creditApplication,
  register,
  errors,
  control,
  listHolidays,
  watch,
  merchantPayments,
  disabledAmountInput,
  loanTermChoicesList,
  isLoadingTerm,
}: Props) => {
  const { t } = useTranslation()
  const deliveryOn = watch('deliveryOn') as string
  const paymentPlanId = watch('paymentPlanId') as string

  const firstPaymentDateOptions = useComputeFirstPaymentDateOptions(deliveryOn, listHolidays)

  const shouldDisableDate = useCallback(
    (date: Date) => {
      return cantSelectDate(date, listHolidays)
    },
    [listHolidays],
  )

  const hasVariableInterest = isVariableInterest(paymentPlanId)
  const programConfig = programConfigByFinancingProgramId(creditApplication.financingProgramId)
  const availableFrequencyPayments = programConfig.frequencyPayments

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={4}>
        <InputTextField
          label={t('worksheet.requestedAmount')}
          fullWidth
          disabled={disabledAmountInput}
          {...register('amountRequested')}
          error={errors.amountRequested}
          defaultValue={creditApplication?.worksheet?.amountRequested}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <DatePicker
          name="deliveryOn"
          control={control}
          error={errors?.deliveryOn}
          label={t('worksheet.activationDate')}
          disablePast
          minDate={new Date()}
          shouldDisableDate={shouldDisableDate}
          maxDate={creditApplication?.expiresOn}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          disabled={hasVariableInterest || isLoadingTerm}
          valueList={loanTermChoicesList}
          label={t('worksheet.loanTerm') as string}
          {...register('term')}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          valueList={availableFrequencyPayments.map((item) => {
            return { label: `common.${item}`, value: item }
          })}
          label={t('worksheet.paymentFrequency') as string}
          {...register('paymentFrequency')}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          valueList={firstPaymentDateOptions?.map((item) => ({
            label: formatDate(item),
            value: formatDate(item),
          }))}
          label={t('worksheet.firstPaymentOn') as string}
          {...register('firstPaymentOn')}
          error={errors?.firstPaymentOn}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          valueList={merchantPayments?.map((item) => ({
            label: item.label.includes(EPaymentPlan.regularDailyInterests)
              ? t(`ePaymentPlan.${item.label as EPaymentPlan}`)
              : item.label,
            value: item.value,
          }))}
          label={t('worksheet.paymentPlan') as string}
          {...register('paymentPlanId')}
          error={errors?.paymentPlanId}
        />
      </Grid>
    </Grid>
  )
}

export default WorksheetDetails
